import React, { useEffect, useState } from "react";
import axios from "axios";

import SidebarStudent from "../../components/Student/SidebarStudent";
import VisibilityIcon from '@mui/icons-material/Visibility';


function SupportStudent() {
    const pathServer = process.env.REACT_APP_API_URL    //const pathLocal="http://localhost:4000"; 

    const [documents, setDocuments] = useState([{}]);

    const [matieres, setMatieres] = useState([{}]);
    const [matiereSelected, setMatiereSelected] = useState();


    const [sections, setSections] = useState([{}]);
    const [sectionSelected, setSectionSelected] = useState();


    const [classes, setClasses] = useState([{}]);
    const [classeSelected, setClasseSelected] = useState();

    const [namedoc, setNameDoc] = useState("");

    const [type, setType] = useState("");

    useEffect(() => {
        // get all matiers
        axios.get(`${pathServer}/getallMatiere`)
            .then(async (res) => {
                setMatieres(res.data);
            })
            .catch((e) => {
            }
            );

        // get all section 
        axios.get(`${pathServer}/getallsections`)
            .then(async (res) => {
                setSections(res.data);
            })
            .catch((e) => {
            }
            );
        // get all class 
        axios.get(`${pathServer}/getallClass`)
            .then(async (res) => {
                setClasses(res.data);
            })
            .catch((e) => {
            }
            );


    }, [pathServer])


    const listMatiere = matieres.map(mat =>
        <option key={mat.id} value={mat.Name} > {mat.Name} </option>
    );

    const listSections = sections.map(mat =>
        <option key={mat.id} value={mat.Name} > {mat.Name} </option>
    );


    const listClasses = classes.map(mat =>
        <option key={mat.id} value={mat.Name} > {mat.Name} </option>
    );


    useEffect(() => {
        axios.get(pathServer + "/getallsupport")
            .then(async (res) => {
                setDocuments(res.data);
            })
            .catch((e) => {
            }
            );
    }, [pathServer]);

    const OpenDocumentInNewOnglet = (doc, e) => {
        e.preventDefault();
        console.log(doc);
        window.open(`${pathServer}/${doc}`, '_blank');
    };


    const listItems = documents
    
        .filter(doc => doc.Name?.toLowerCase().includes(namedoc?.toLowerCase() || ""))
        .filter(doc => doc.Matiere?.toLowerCase().includes(matiereSelected?.toLowerCase() || ""))
        .filter(doc => doc.Section?.toLowerCase().includes(sectionSelected?.toLowerCase() || ""))
        .filter(doc => doc.Type?.toLowerCase().includes(type?.toLowerCase() || ""))
        .filter(doc => doc.Class?.toLowerCase().includes(classeSelected?.toLowerCase() || ""))
        .map((doc, index) => (
            <tr key={doc.id || index}>
                <td>{doc.Name}</td>
                <td>{doc.Matiere}</td>
                <td>{doc.Type}</td>
                <td>{doc.Section}</td>
                {/* <td>{doc.Niveau} Year</td> */}
                <td>{doc.Class}</td>
                <td>{doc.Doc} <VisibilityIcon onClick={(e) => OpenDocumentInNewOnglet(doc.Doc, e)}
                    style={{ color: '#54b48d', height: '25px', width: '40px', paddingLeft: '10px' }} /> </td>
            </tr>
        ));
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-2">
                        <SidebarStudent />
                    </div>
                    <div className="col-10" style={{ marginTop: "80px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-2 mb-3">
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        id="namedoc"
                                        name="namedoc"
                                        placeholder="Name Document"
                                        value={namedoc}
                                        onChange={(e) => setNameDoc(e.target.value)}
                                    />
                                </div>
                                <div className="col-2 mb-3">
                                    <select
                                        className="form-select"
                                        id="aaa"
                                        name="aaa"
                                        value={matiereSelected}
                                        onChange={(e) => setMatiereSelected(e.target.value)}
                                    >
                                        <option value="" >Matiere</option>
                                        {listMatiere}
                                    </select>
                                </div>

                                <div className="col-2 mb-3">
                                    <select
                                        className="form-select"
                                        id="bbb"
                                        name="bbb"
                                        value={sectionSelected}
                                        onChange={(e) => setSectionSelected(e.target.value)}
                                    >
                                        <option value="" >Section</option>
                                        {listSections}
                                    </select>
                                </div>
                                <div className="col-2 mb-3">
                                    <select
                                        className="form-select"
                                        id="bbb"
                                        name="bbb"
                                        value={classeSelected}
                                        onChange={(e) => setClasseSelected(e.target.value)}
                                    >
                                        <option value="" >Class</option>
                                        {listClasses}
                                    </select>
                                </div>
                                <div className="col-2 mb-3">
                                    <select
                                        className="form-select"
                                        id="bbb"
                                        name="bbb"
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}
                                    >
                                        <option value="" >Type</option>
                                        <option value="Cours" >Cours</option>
                                        <option value="Exam" >Exam</option>
                                    </select>                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-12">
                                    <div className="recent-orders">
                                        <h2>Support</h2>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Matiere</th>
                                                    <th>Type</th>
                                                    <th>Section</th>
                                                    {/* <th>Niveau</th> */}
                                                    <th>Class</th>
                                                    <th>doc</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listItems}
                                            </tbody>
                                        </table>
                                        <a href="/">...</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SupportStudent;