import "../../css/popup.css";
import axios from "axios";
import React, { useEffect, useState } from "react";

const PopUpProfDetails = (props) => {
    const pathServer = process.env.REACT_APP_API_URL;

    const [classes, setClasses] = useState([]);
    const [matieres, setMatieres] = useState([]);

    useEffect(() => {
        axios.get(`${pathServer}/classProfs/` + props.user.id)
            .then((response) => {
                setClasses(response.data);
            })
            .catch((error) => console.error("Error fetching all classes:", error));
    }, [pathServer, props.user.id]);

    useEffect(() => {
        axios.get(`${pathServer}/matieresProfForProf/` + props.user.id)
            .then((response) => {
                setMatieres(response.data);
            })
            .catch((error) => console.error("Error fetching all matires:", error));
    }, [pathServer, props.user.id]);

    const listMatieres = matieres.map((c) => (
        <option key={c.id} value={c.id}>{c.Name}</option>
    ));

    const listItems = classes.map((c) => (
        <option  key={c.id} value={c.id}>{c.Name}</option>
    ));

    return (
        <div className="popup-box">
            <div className="box">
                <span className="close-icon" onClick={props.handleClose}>×</span>

                {props.content}
                <label htmlFor="role" className="form-label" style={{ textAlign: "left" }}>
                    Class
                </label>
                <select
                    className="form-select"
                    id="role"
                    name="role"
                >
                    <option value="">Les classe Affecté</option>
                    {listItems}
                </select>
                <br />
                <br />
                <label htmlFor="role" className="form-label" style={{ textAlign: "left" }}>
                    Matiere
                </label>
                <select
                    className="form-select"
                    id="role"
                    name="role"
                >
                    <option value="">Les Matieres Affecté</option>
                    {listMatieres}
                </select>
                <br />
                <br />

                <br />
                <br />
                <br />
            </div>
        </div>
    );
};

export default PopUpProfDetails;
