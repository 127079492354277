import React from "react";
import "../../css/popup.css"

const Popup = props => {
  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={props.handleClose}>×</span>

        {props.content}
        {
          props.customButton ? (
            props.customButton
          ) : (
            <button onClick={() => { props.handleClose(); window.location.reload(false); }} type="button" className="btn btn-outline-success">
              Success
            </button>
          )
        }
      </div>
    </div>
  );
};

export default Popup;
