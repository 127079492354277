import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const pathServer = process.env.REACT_APP_API_URL    


export const getAuth = () => {
    return async function (dispatch) {
        return await axios
            .get(`${pathServer}/usergetlogged`, {
                withCredentials: true,
            })
            .then((res) => {
                dispatch(userActions.authentified(res.data))
            })
            .catch((err) => {
                dispatch(userActions.notauthentified());});
    };
};


export const logout = () => {
    return async function (dispatch) {
        return await axios
            .get(`${pathServer}/logout`, {
                withCredentials: true,
            })
            .then((res) => dispatch(userActions.notauthentified()))
            .catch();
    };
};

const userSlice = createSlice({
    name: "auth",
    initialState: {
        isAuth: false,
        user: {
            "id": 0,
            "Name": "",
            "Email": "",
            "Birthday": "",
            "tel": 0,
            "Role": "Student",
            "class": 0,
            "token": ""
        },
    },
    reducers: {
        authentified(state, action) {
            state.isAuth = true;
            //console.log("teest redux "+ action.payload);
            state.user = action.payload;
        },
        notauthentified(state) {
            state.isAuth = false;
            state.user = {
                "id": 0,
                "Name": "",
                "Email": "",
                "Birthday": "",
                "tel": 0,
                "Role": "Student",
                "class": 0,
                "token": "",
            };
        },
        updateuser(state, action) {
            state.user[action.payload.type] = action.payload.value;
        },
    },
});

export const userActions = userSlice.actions;
export default userSlice;

