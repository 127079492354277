import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageIllustration from '../assests/image_illustration.jpg';
import axios from "axios";
import Navbar from "./Navbar";
import PopupForgetPwd from "./Widget/PopupForgetPwd";
import { useParams } from 'react-router-dom';



function ForgetPassword() {
    const pathServer = process.env.REACT_APP_API_URL

    const { token } = useParams();

    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [erroremessage, seterrormessage] = useState("");
    const history = useNavigate();


    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        axios.get(`${pathServer}/verfytoken?token=${token}`)
            .then((res) => {
                console.log(res.data);
                setemail(res.data);
            })
            .catch((e) => {
                history('/');
                seterrormessage(e.response.data.message);
            })
    }, [history, pathServer, token]);



    const SaveNewPassword = (e) => {
        e.preventDefault();
        var loginInfo = { password: password };

        axios.put(`${pathServer}/forgetpassword/${email}`, loginInfo)
            .then((res) => {
                console.log(res.data);
                history("/");
                window.location.reload(true);
            })
            .catch((e) => {
                console.log(e);
                seterrormessage(e.response.data.message);
            }
            );
    };

    return (
        <>
            <Navbar />
            <div className="container">
                <div className="row">
                    {isOpen && <PopupForgetPwd
                        content={<>
                            <b>Terminé</b>
                            <br />
                            <br />
                            <p></p>
                        </>}
                        handleClose={togglePopup}
                    />}
                    <div className="col-md-10 m-auto mt-5 bg-white pb-5 pt-5 pe-5 px-5 mb-5 rounded-5">
                        <h1 className="mb-5">Réinitialiser le mot de passe</h1>
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <img
                                        src={ImageIllustration}
                                        alt=""
                                        width="80%"
                                    />
                                </div>
                                <div className="col-6">
                                    <div className="container">
                                        <form onSubmit={SaveNewPassword}>

                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <label
                                                        for="password"
                                                        className="form-label"
                                                        style={{ textAlign: "left" }}
                                                    >
                                                        Mot de Passe
                                                    </label>
                                                    <input
                                                        required
                                                        type="password"
                                                        className="form-control"
                                                        id="password"
                                                        name="password"
                                                        placeholder="Mot de Passe"
                                                        onChange={(e) => setpassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            {erroremessage !== "" ? (
                                                <p className="text-danger">{erroremessage}</p>
                                            ) : (
                                                ""
                                            )}
                                            <br />
                                            <div className="Search__actions">
                                                <button className="btn btn-custom rounded-5" type="submit">
                                                    Sauvegarder
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgetPassword;