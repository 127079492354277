import "../../css/popup.css";
import axios from "axios";
import React, { useEffect, useState } from "react";

const PopupAffectStudent = (props) => {
    const pathServer = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [classSelected, setClass] = useState("");
    const [matieres, setMatieres] = useState([]);
    const [matieresSelected, setMatiere] = useState("");
    const [message, setMessage] = useState("");
    const [message2, setMessage2] = useState("");


    const ClosePopUp = (e) => {
        //e.preventDefault(); // Prevent the default action

        // Set up a timer to call props.handleClose after 1 second
        setTimeout(() => {
            props.handleClose();
            window.location.reload(false);
        }, 1000); // 1000 milliseconds = 1 second
    };


    const affectUserToClass = () => {
        if (props.user.Role === "Student") {
            const affected = { iduser: props.user.id, idclass: classSelected };
            axios.post(`${pathServer}/affectclass`, affected)
                .then(() => {
                    setMessage("Affecté");
                    ClosePopUp();
                })
                .catch((error) => console.error("Error affecting user to class:", error));
        } else if (props.user.Role === "Prof") {
            const affectedToProf = { id_prof: props.user.id, id_class: classSelected };
            axios.post(`${pathServer}/assignProfToClass`, affectedToProf)
                .then(() => {
                    setMessage("Class affecté");
                    ClosePopUp();
                })
                .catch((error) => console.error("Error assigning prof to class:", error));
        }
    };

    const affectMatiereToUser = () => {
        if (props.user.Role === "Prof") {
            const affectedToProf = { id_prof: props.user.id, id_matiere: matieresSelected };
            axios.post(`${pathServer}/assignMatiereToProf`, affectedToProf)
                .then(() => {
                    setMessage2("Matiere affecté");
                    ClosePopUp();
                })
                .catch((error) => console.error("Error assigning matière to prof:", error));
        }
    };

    useEffect(() => {
        axios.get(`${pathServer}/getallClass`)
            .then((response) => setData(response.data))
            .catch((error) => console.error("Error fetching classes:", error));
    }, [pathServer]);

    useEffect(() => {
        if (props.user.Role === "Student") return;
        axios.get(`${pathServer}/getallMatiere`)
            .then((response) => setMatieres(response.data))
            .catch((error) => console.error("Error fetching matieres:", error));
    }, [pathServer, props.user.Role]);

    const listMatieres = matieres.map((c) => (
        <option key={c.id} value={c.id}>{c.Name}</option>
    ));

    const listItems = data.map((c) => (
        <option key={c.id} value={c.id}>{c.Name}</option>
    ));

    return (
        <div className="popup-box">
            <div className="box">
                <span className="close-icon" onClick={props.handleClose}>×</span>

                {props.content}
                <label htmlFor="role" className="form-label" style={{ textAlign: "left" }}>
                    Class
                </label>
                <select
                    className="form-select"
                    id="role"
                    name="role"
                    value={classSelected}
                    onChange={(e) => setClass(e.target.value)}
                    required
                >
                    <option value="">Choisir une classe</option>
                    {listItems}
                </select>
                <br />
                <button onClick={affectUserToClass} type="button" className="btn btn-outline-success">Affecter</button>
                <br />
                {message !== "" ? (
                    <p className="text-info">{message}</p>
                ) : (
                    ""
                )}
                {props.user.Role === "Prof" && (
                    <>
                        <br />
                        <br />
                        <label htmlFor="mat" className="form-label" style={{ textAlign: "left" }}>
                            Matiere
                        </label>
                        <select
                            className="form-select"
                            id="mat"
                            name="mat"
                            value={matieresSelected}
                            onChange={(e) => setMatiere(e.target.value)}
                            required
                        >
                            <option value="">Choisir une matière</option>
                            {listMatieres}
                        </select>
                        <br />
                        <button onClick={affectMatiereToUser} type="button" className="btn btn-outline-success">Affecter</button>
                        {message2 !== "" ? (
                            <p className="text-info">{message}</p>
                        ) : (
                            ""
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default PopupAffectStudent;
