import "../../css/popup.css"
import axios from "axios";
import React, { useState } from "react";



const PopupForgetPwd = props => {
    const pathServer = process.env.REACT_APP_API_URL    //const pathLocal="http://localhost:4000"; 

    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");


    const sendMeEmail = (e) => {
        var b = { email: email };

        axios.post(`${pathServer}/generatetoken`, b)
            .then(async (res) => {
                console.log(res.data);
                setMessage(res.data.message)
            })
            .catch((e) => {
            }
            );
    }

    const done = (e) => {
        props.handleClose();
    }


    return (
        <div className="popup-box">
            <div className="box">
                <span className="close-icon" onClick={done}>×</span>

                {props.content}

                <label
                    htmlFor="email"
                    className="form-label"
                    style={{ textAlign: "left" }}
                >
                    Adresse Mail Professionnelle
                </label>

                <br />
                <br />

                <div className="container">
                    <div className="row">
                        <div className="col-7">
                            <input
                                required
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                placeholder="prenom.nom@classy.com"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="col-2"></div>
                        <div className="col-3">
                            <button onClick={sendMeEmail} type="button" className="btn btn-outline-success">Envoyer un e-mail</button>
                        </div>
                    </div>
                </div>

                <br />
                <br />
                {message !== "" ? (
                                                <p className="text-info">{message}</p>
                                            ) : (
                                                ""
                                            )}
                <button onClick={done} type="button" className="btn btn-outline-success">Terminé</button>
            </div>
        </div>
    );
};

export default PopupForgetPwd;
