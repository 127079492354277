import '../../../css/card.css'


function CardProgression(props) {
    return (
        <>
                <div className="sales">
                    <div className="status">
                        <div className="info">
                            <h3>Absence Today</h3>
                            <h1> {props.absence} </h1>
                        </div>
                        <div className="progresss">
                            <svg>
                                <circle cx="38" cy="38" r="36"></circle>
                            </svg>
                            <div className="percentage">
                                <p>+81%</p>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}

export default CardProgression;