import SidebarAdmin from "./SidebarAdmin";
import React, { useState } from "react";
//import { useNavigate } from "react-router-dom";
//import { useSnackbar } from "notistack";
import axios from "axios";
import Popup from "../Widget/Popup";



function AddUser() {
  const pathServer = process.env.REACT_APP_API_URL  //const pathLocal="http://localhost:4000"; 


  //const { enqueueSnackbar } = useSnackbar();
  //const history = useNavigate();

  const [erroremessage, seterrormessage] = useState("");
  const [user, setUser] = useState({
    "name": "",
    "email": "",
    "password": "",
    "birthday": "2023-12-06",
    "tel": "",
    "role": ""
  });

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  }


  const onSubmitHandler = (e) => {
    e.preventDefault();

    axios.post(`${pathServer}/addstudentandsendemail`, user, { // old endpoint /adduser new one  /addstudentandsendemail
      withCredentials: true,
    })
      .then((res) => {
        if (res.status === 200) {
          togglePopup();
          //window.location.reload(false);
        }
      })
      .catch((e) => {
        seterrormessage(e.response.data.message);
        // UX go to users to affect if its students in a class 
      }
      );
  }
  return (
    <>
      <SidebarAdmin />
      <div className="container">
        <br />
        <br />
        <br />
        <div className="row">

          <div className="col-md-6 m-auto mt-5 bg-white pb-5 pt-5 pe-5 px-5 mb-5 rounded-5">
            <br />
            <h1 className="mb-5"> Ajouter un utilisateur </h1>
            {isOpen && <Popup
              content={<>
                <b>utilisateur ajouté avec succèss </b>
                <br />
                <p>{user.email}</p>
              </>}
              handleClose={togglePopup}
            />}
            <div className="container">
              <form>
                <div className="row">
                  <div className="col-6 mb-3">
                    <label
                      htmlFor="prenom"
                      className="form-label"
                      style={{ textAlign: "left" }}
                    >
                      Nom et prénom
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="prenom"
                      name="prenom"
                      placeholder="Nom ..."
                      value={user.name}
                      onChange={(e) => setUser({ ...user, name: e.target.value })}
                    />
                  </div>
                  <div className="col-6 mb-3">
                    <label
                      htmlFor="nom"
                      className="form-label"
                      style={{ textAlign: "left" }}
                    >
                      Anniversaire
                    </label>
                    <input
                      required
                      type="date"
                      className="form-control"
                      id="nom"
                      name="nom"
                      placeholder=""
                      value={user.birthday}
                      onChange={(e) => setUser({ ...user, birthday: e.target.value })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <label
                      htmlFor="email"
                      className="form-label"
                      style={{ textAlign: "left" }}
                    >
                      Email
                    </label>
                    <input
                      required
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Email "
                      value={user.email}
                      onChange={(e) => setUser({ ...user, email: e.target.value })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-8 mb-3">
                    <label
                      htmlFor="pwd"
                      className="form-label"
                      style={{ textAlign: "left" }}
                    >
                      Mot de passe
                    </label>
                    <input
                      required
                      type="password"
                      className="form-control"
                      id="pwd"
                      name="pwd"
                      placeholder="Password"
                      value={user.password}
                      onChange={(e) => setUser({ ...user, password: e.target.value })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-8 mb-3">
                    <label
                      htmlFor="telephone"
                      className="form-label"
                      style={{ textAlign: "left" }}
                    >
                      N° telephone
                    </label>
                    <input
                      required
                      type="number"
                      className="form-control"
                      id="telephone"
                      name="telephone"
                      placeholder="N° telephone"
                      value={user.telephone}
                      onChange={(e) => setUser({ ...user, tel: e.target.value })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mb-3">
                    <label
                      htmlFor="role"
                      className="form-label"
                      style={{ textAlign: "left" }}
                    >
                      Role
                    </label>
                    <select
                      className="form-select"
                      id="role"
                      name="role"
                      value={user.role}
                      onChange={(e) => setUser({ ...user, role: e.target.value })}
                      required
                    >
                      <option selected value="Student">Student</option>
                      <option value="Admin">Admin</option>
                      <option value="Prof">Prof</option>
                    </select>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-3">
                      {erroremessage !== "" ? (
                        <p className="text-danger">{erroremessage}</p>
                      ) : (
                        ""
                      )}
                      <br />
                    </div>
                  </div>
                </div>
                <br />
                <div className="Search__actions">
                  <button onClick={onSubmitHandler} className="btn btn-outline-primary btn-custom rounded-5">
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddUser;