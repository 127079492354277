import { DoughnutChart } from "../Chart/DoughnutChart";
import { useNavigate } from "react-router-dom";



function CardClassDetailsStats(props) {
  const history = useNavigate();

  const classe = props.classe;
  const semester = classe.Matiere;

  const listMatiere = semester.map(mat => {
    let numberSemester = mat.semestre;
    let numberAbsenceInsemester = mat.nbhAbsentSemester;
    const data = {
      labels: mat.matieres.map(m => m.Name),
      datasets: [
        {
          label: '# ',
          data: mat.matieres.map(m => m.nbhAbsent),
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };

    return (
      <div className="col-6" onClick={() => console.log(classe.Name)}>
        <p>Semester {numberSemester}</p>
        <DoughnutChart data={data} />
        <br />
        <p>Number Absent In <b> {numberAbsenceInsemester} </b></p>
        <br />
        <br />
      </div>

    )
  }
  );


  const handleDetails = () => {
    // call this path with idclass and run new API that get details Class
    history(`/Absenceadmin/statsClass/${classe.id}`);
  }


  return (
    <>
      <h2 color="#378FE7"> {classe.Name} </h2>
      <p> Number Of Absence this Year <b> {classe.NumberHoureAbsentYear} </b> </p>
      {listMatiere}
      <button type="button" class="btn btn-outline-info col-2 m-2" onClick={handleDetails}>Details</button>
      <br />
    </>
  );
}

export default CardClassDetailsStats;