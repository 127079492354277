import SidebarAdmin from "./SidebarAdmin";
import CardProgression from "../Widget/Card/CardProgression";



import React, { useEffect, useState } from "react";
import axios from "axios";
import CardMatiere from "../Widget/Card/CardMatiere";
import { BarChart } from "../Widget/Chart/BarChart";
import CardClassDetailsStats from "../Widget/Card/CardClassDetailsStats";


function AbsenceAdmin() {
   const pathServer = process.env.REACT_APP_API_URL   //const pathLocal="http://localhost:4000"; 

   const [absencesToday, setAbsencesToday] = useState(0);
   const [matieres, setMatieres] = useState([{}]);
   const [maindata, setMainData] = useState([]);
   const [semesterSelected, setSemester] = useState("All");





   useEffect(() => {
      axios.get(`${pathServer}/getallabsencesoftoday`)
         .then(async (res) => {
            setAbsencesToday(res.data);
         })
         .catch((e) => {
         }
         );
   }, [pathServer])


   useEffect(() => {
      axios.get(`${pathServer}/getallstatsformatiere`)
         .then(async (res) => {
            setMatieres(res.data);
         })
         .catch((e) => {
         }
         );
   }, [pathServer])

   useEffect(() => {
      axios.get(`${pathServer}/getallstatsforclasses`)
         .then(async (res) => {
            setMainData(res.data);
         })
         .catch((e) => {
         }
         );
   }, [pathServer])

   const listCardMatiere = matieres.map(mat =>
      <CardMatiere Matiere={mat} />
   );

   const listeCardDetailsStats = maindata.map(c =>
      <CardClassDetailsStats classe={c} />
   );

   const MultiplyNumbers = (a, b) => {
      return (a * b);
   };

   const options = {
      responsive: true,
      plugins: {
         legend: {
            position: 'top',
         },
         title: {
            display: true,
            text: 'Stats Absences All Classes',
         },
      },
   };
   const labels = maindata.map(c => c.Name);
   const data = {
      labels,
      datasets: [
         {
            label: 'Absence',
            data: maindata.map(c => c.NumberHoureAbsentYear),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
         },
         {
            label: 'Number of Hours Per Year',
            data: maindata.map(c => MultiplyNumbers(c.nbhYear, c.Student.length)),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
         },
      ],
   };

   return (

      <div className="container-fluid">
         <div className="row">
            <div className="col-2">
               <SidebarAdmin />
            </div>
            <div className="col-10" style={{ marginTop: "100px" }}>
               <div className="container">
                  <div className="row">
                     <div className="col-7">
                        <div className="container-fluid bg-white rounded-5">
                           <div className="row">
                              <div className="col-12">
                                 <BarChart options={options} data={data} />
                              </div>
                           </div>
                           <br />
                           <div className="row">
                              <div className="col-12">
                                 <CardProgression absence={absencesToday} />
                              </div>
                           </div>
                           <br />
                           <div className="row">
                              <div className="col-2 mt-5 mb-2">
                                 <label
                                    htmlFor="role"
                                    className="form-label"
                                 >
                                    Semester
                                 </label>
                                 <select
                                    className="form-select"
                                    id="semester"
                                    name="semester"
                                    value={semesterSelected}
                                    onChange={(e) => setSemester(e.target.value)}
                                 >
                                    <option selected value="All">All</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                 </select>
                              </div>
                              {listeCardDetailsStats}
                           </div>
                           <br/>
                        </div>
                     </div>
                     <div className="col-1"></div>
                     <div className="col-4">
                        {listCardMatiere}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

   );
}

export default AbsenceAdmin;