// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Popup Background Overlay */
.popup-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); /* Dark semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it overlays other content */
}

/* Popup Container */
.box {
  position: relative;
  width: 40%;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-height: 80vh;
  overflow-y: auto;
  z-index: 1001; /* Above the overlay */
}

/* Close Icon */
.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  background: #ff5e5e; /* Red close button */
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 30px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease;
  z-index: 1002; /* Ensure it's on top */
}

/* Close Icon Hover Effect */
.close-icon:hover {
  background: #ff1e1e; /* Darker red on hover */
}
`, "",{"version":3,"sources":["webpack://./src/css/popup.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,8BAA8B,EAAE,qCAAqC;EACrE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,qCAAqC;AACtD;;AAEA,oBAAoB;AACpB;EACE,kBAAkB;EAClB,UAAU;EACV,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,yCAAyC;EACzC,gBAAgB;EAChB,gBAAgB;EAChB,aAAa,EAAE,sBAAsB;AACvC;;AAEA,eAAe;AACf;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,WAAW;EACX,YAAY;EACZ,mBAAmB,EAAE,qBAAqB;EAC1C,kBAAkB;EAClB,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,gCAAgC;EAChC,aAAa,EAAE,uBAAuB;AACxC;;AAEA,4BAA4B;AAC5B;EACE,mBAAmB,EAAE,wBAAwB;AAC/C","sourcesContent":["/* Popup Background Overlay */\n.popup-box {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  background: rgba(0, 0, 0, 0.6); /* Dark semi-transparent background */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000; /* Ensure it overlays other content */\n}\n\n/* Popup Container */\n.box {\n  position: relative;\n  width: 40%;\n  background: #f9f9f9;\n  border-radius: 10px;\n  padding: 20px;\n  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);\n  max-height: 80vh;\n  overflow-y: auto;\n  z-index: 1001; /* Above the overlay */\n}\n\n/* Close Icon */\n.close-icon {\n  position: absolute;\n  top: 15px;\n  right: 15px;\n  width: 30px;\n  height: 30px;\n  background: #ff5e5e; /* Red close button */\n  border-radius: 50%;\n  color: #fff;\n  text-align: center;\n  line-height: 30px;\n  font-size: 20px;\n  font-weight: bold;\n  cursor: pointer;\n  transition: background 0.3s ease;\n  z-index: 1002; /* Ensure it's on top */\n}\n\n/* Close Icon Hover Effect */\n.close-icon:hover {\n  background: #ff1e1e; /* Darker red on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
