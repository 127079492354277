import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageIllustration from '../assests/image_illustration.jpg';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getAuth } from "../Redux/userReducer";
import Navbar from "./Navbar";
import PopupForgetPwd from "./Widget/PopupForgetPwd";


function SignIn() {
    const pathServer = process.env.REACT_APP_API_URL

    const [isOpen, setIsOpen] = useState(false);
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [erroremessage, seterrormessage] = useState("");
    const history = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);



    const togglePopup = () => {
        setIsOpen(!isOpen);
    }


    useEffect(() => {
        dispatch(getAuth());
        if (auth.isAuth) {
            if (auth.user.Role === "Student") {
                history("/Absenceuser");
            }

            if (auth.user.Role === "Admin") {
                history("/Useradmin");
            }
        }
    }, [auth.isAuth, auth.user.Role, dispatch, history]);



    const login = (e) => {
        e.preventDefault();
        var loginInfo = { email, password };

        axios.post(`${pathServer}/login`, loginInfo, {
            withCredentials: true,
        })
            .then((res) => {
                //console.log(res.status)
                if (res.status === 200) {
                    //console.log(res.data.Role==="Prof");
                    if (res.data.Role === "Student") {
                        history("/Absenceuser");
                    }

                    if (res.data.Role === "Admin") {
                        history("/Useradmin");
                    }

                    if (res.data.Role === "Prof") {
                        history("/AbsenceClassProf/0/0");
                    }
                }
            })
            .catch((e) => {
                seterrormessage(e.response.data.message);
            }
            );
    };

    return (
        <>
            <Navbar />
            <div className="container">
                <div className="row">
                    {isOpen && <PopupForgetPwd
                        content={<>
                            <b>Oublier le mot de passe</b>
                            <br />
                        </>}
                        handleClose={togglePopup}
                    />}
                    <div className="col-md-10 m-auto mt-5 bg-white pb-5 pt-5 pe-5 px-5 mb-5 rounded-5">
                        <h1 className="mb-5">Se Connecter</h1>
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <img
                                        src={ImageIllustration}
                                        alt=""
                                        width="80%"
                                    />
                                </div>
                                <div className="col-6">
                                    <div className="container">
                                        <form onSubmit={login}>
                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <label
                                                        htmlFor="email"
                                                        className="form-label"
                                                        style={{ textAlign: "left" }}
                                                    >
                                                        Adresse Mail Professionnelle
                                                    </label>
                                                    <input
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        id="email"
                                                        name="email"
                                                        placeholder="prenom.nom@classy.com"
                                                        onChange={(e) => setemail(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <label
                                                        htmlFor="Mot de Passe"
                                                        className="form-label"
                                                        style={{ textAlign: "left" }}
                                                    >
                                                        Mot de Passe
                                                    </label>
                                                    <input
                                                        required
                                                        type="password"
                                                        className="form-control"
                                                        id="password"
                                                        name="password"
                                                        placeholder="Mot de Passe"
                                                        onChange={(e) => setpassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            {erroremessage !== "" ? (
                                                <p className="text-danger">{erroremessage}</p>
                                            ) : (
                                                ""
                                            )}

                                            <br />
                                            <div className="Search__actions">
                                                <button className="btn btn-custom rounded-5" type="submit">
                                                    Se connecter
                                                </button>
                                            </div>
                                            <br />
                                            <p onClick={togglePopup} style={{ color: "blue" }}>
                                                Mot de passe oublié ?
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignIn;