import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'top',
//     },
//     title: {
//       display: true,
//       text: 'Chart.js Bar Chart',
//     },
//   },
// };



// export const data = {
//   labels,// props.allclass.map(c => c.Name);
//   datasets: [
//     {
//       label: 'Absence',
//       data: [12312,523,12,52312,52312,523],// props.allclass.map(c=>c.NumberHoureAbsentYear)
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//     {
//       label: 'Number of Hours Per Year',
//       data: [122,523,12,52312,52312,523],// props.allclass.map(c=>c.nbhYear * c.Student.lenght)
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };

export function BarChart(props) {

  return <Bar options={props.options} data={props.data} />;
}
