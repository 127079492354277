import PieChart from "../Chart/PieChart";



function CardMatiere(props) {


    const data = {
        labels: ['Absent', 'Present',],
        datasets: [
            {
                label: '# ',
                data: [props.Matiere.absencein, (props.Matiere.nbrStudent * props.Matiere.nbh) - props.Matiere.absencein],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };


    const divideNumbers = (a, b) => {
        if (b === 0) {
            // Handling division by zero
            return 0;
        }
        return (a / b).toFixed(2);
    };

    return (
        <>
            <div class="card m-4" style={{width:"400px"}}>
                <h5 class="card-title">{props.Matiere.Name}</h5>
                <div class="card-body">
                    <PieChart data={data} />
                    <p class="card-text"> Percent <b> {(1 - divideNumbers(props.Matiere.absencein, props.Matiere.nbrStudent * props.Matiere.nbh)) * 100} % </b> </p>
                </div>
            </div>
        </>
    );
}
//<p> <CircularProgress percent={(1 - divideNumbers(props.Matiere.absencein, props.Matiere.nbrStudent * props.Matiere.nbh)) * 100}  /> </p>


export default CardMatiere;