import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import ImageIllustration from '../../assests/image_illustration.jpg';
import axios from "axios";
import Navbar from "../Navbar";
import Popup from "../Widget/Popup";



function RestPassword() {
    const pathServer = process.env.REACT_APP_API_URL

    const [email, setemail] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const [password, setpassword] = useState("");
    const history = useNavigate();
    const { token } = useParams();


    useEffect(() => {
        axios.get(`${pathServer}/verfytoken?token=${token}`)
            .then((res) => {
                if (res.status === 200 && typeof (res.data) === "string") {
                    setemail(res.data);
                }
                else {
                    history("/");

                }
            })
            .catch((e) => {
                history("/");
            }
            );
    },);



    const togglePopup = () => {
        setIsOpen(!isOpen);
    }


    const RestPasswordFunction = (e) => {
        e.preventDefault();
        var loginInfo = { email, password };

        axios.put(`${pathServer}/updatepasswordstudent?token=${token}`, loginInfo, {
            withCredentials: true,
        })
            .then((res) => {
                if (res.status === 200) {
                    // PopUp that you password was updated
                    togglePopup();
                }
                else {
                    history("/");
                }
            })
            .catch((e) => {

            }
            );
    };
    return (
        <>
            <Navbar />
            <div className="container">
                <div className="row">
                    {isOpen && <Popup
                        content={<>
                            <h3>Mot de passe mis à jour avec succès </h3>
                            <br />
                            <p></p>
                        </>}
                        customButton={<button onClick={() => { history("/"); }} type="button" class="btn btn-outline-success">Se connecter maintenant</button>}
                        handleClose={togglePopup}
                    />}
                    <div className="col-md-10 m-auto mt-5 bg-white pb-5 pt-5 pe-5 px-5 mb-5 rounded-5">
                        <h1 className="mb-5">Rest Password</h1>
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <img
                                        src={ImageIllustration}
                                        alt=""
                                        width="80%"
                                    />
                                </div>
                                <div className="col-6">
                                    <div className="container">
                                        <form onSubmit={RestPasswordFunction}>
                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <label
                                                        for="email"
                                                        className="form-label"
                                                        style={{ textAlign: "left" }}
                                                    >
                                                        Adresse Mail Professionnelle
                                                    </label>
                                                    <input
                                                        readOnly
                                                        type="text"
                                                        className="form-control"
                                                        id="email"
                                                        name="email"
                                                        placeholder={email}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <label
                                                        for="password"
                                                        className="form-label"
                                                        style={{ textAlign: "left" }}
                                                    >
                                                        Mot de Passe
                                                    </label>
                                                    <input
                                                        required
                                                        type="password"
                                                        className="form-control"
                                                        id="password"
                                                        name="password"
                                                        placeholder="Mot de Passe"
                                                        onChange={(e) => setpassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <br />
                                            <div className="Search__actions">
                                                <button className="btn btn-custom rounded-5" type="submit">
                                                    Confirmer
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RestPassword;