import "../../css/popup.css"
import axios from "axios";
import React, { useEffect, useState } from "react";



const PopupStudentAbsenceInMatiere = props => {

    const pathServer = process.env.REACT_APP_API_URL    //const pathLocal="http://localhost:4000"; 

    const [data, setData] = useState([{}]);

    const Done = (e) => {
        props.handleClose()
    }


    useEffect(() => {
        const getAll = (e) => {
            axios.get(`${pathServer}/getabsenceofstudentinspecificclassandmetire/${props.idclass}/${props.idmat}`)
                .then((res) => {
                    setData(res.data);
                })
                .catch((e) => {
                }
                );
        }
        // Call the fetchData function when the component mounts
        getAll();

        // Cleanup function (optional): This will be called when the component is unmounted
        return () => {
            // You can perform cleanup here if needed
        };
    }, [pathServer, props.idclass, props.idmat]);

    const listItems = data.map(c =>
            <p value={c.id}> <b>{c.Name} </b> -- {c.absence}    </p>

    );

    return (
        <div className="popup-box">
            <div className="box">
            <span className="close-icon" onClick={Done}>×</span>

                {props.content}
                {listItems}
                <br />
                <br />

                <button onClick={Done} type="button" class="btn btn-outline-success">Terminé</button>
            </div>
        </div>
    );
};

export default PopupStudentAbsenceInMatiere;
