import { configureStore } from "@reduxjs/toolkit";
import userSlice from './userReducer';

const store = configureStore({
    reducer: {
      auth: userSlice.reducer,
      /*profil: profileSlice.reducer,
      signup: signupSlice.reducer,
      */
    },
  });
  
  export default store;
  