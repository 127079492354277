import SidebarAdmin from "../Admin/SidebarAdmin";


function NoteAbsence()
{
    return (
        <> 
        <SidebarAdmin/>
        <p> NoteAbsence </p> 
        </>
    );
}


export default NoteAbsence;