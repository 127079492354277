import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import axios from "axios";
import SidebarProf from './SidebarProf';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { getAuth } from "../../Redux/userReducer"
import { useDispatch, useSelector } from "react-redux";

function ClassesProf() {

    const { classId, className } = useParams();
    const pathServer = process.env.REACT_APP_API_URL    //const pathLocal="http://localhost:4000"; 

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);


    const [data, setData] = useState([{}]);
    const [students, setStudents] = useState([{}]);
    const [name, setName] = useState("");
    const [matieres, setMatieres] = useState([{}]);
    const [matiereSelected, setMatiere] = useState("");

    useEffect(() => {
        if (auth.isAuth === false) {
            dispatch(getAuth());
        }
    }, [auth.isAuth, dispatch]);


    useEffect(() => {
        const getUsers = (e) => {
            var path = "/getallabsencesdetails";
            axios.get(pathServer + path)
                .then(async (res) => {
                    setData(res.data);
                })
                .catch((e) => {
                }
                );
        }
        // Call the fetchData function when the component mounts
        getUsers();

        // Cleanup function (optional): This will be called when the component is unmounted
        return () => {
            // You can perform cleanup here if needed
        };
    }, [pathServer]);


    useEffect(() => {
        const getUsers = (e) => {
            var path = `/getallusersbyclasse/${classId}`;
            axios.get(pathServer + path)
                .then(async (res) => {
                    setStudents(res.data);
                })
                .catch((e) => {
                }
                );
        }
        // Call the fetchData function when the component mounts
        getUsers();

        // Cleanup function (optional): This will be called when the component is unmounted
        return () => {
            // You can perform cleanup here if needed
        };
    }, [classId, pathServer]);


    useEffect(() => {
        axios.get(`${pathServer}/matieresProfForProf/${auth.user.id}`)
            .then(async (res) => {
                setMatieres(res.data);
            })
            .catch((e) => {
            }
            );
    }, [auth.user.id, classId, pathServer]);

    const listMatiere = matieres.map(mat =>
        <option key={mat.id} value={mat.Name} > {mat.Name} </option>
    );

    const countOccurrences = (idsdudent, matiereSelected) => {
        return data.reduce((count, item) => (item.idsdudent === idsdudent && item.matiere === matiereSelected ? count + 1 : count), 0);
    }

    const removeDuplicates = (data) => {
        const ids = new Set();
        const filtered = data.filter(person => {
            if (!ids.has(person.idsdudent)) {
                ids.add(person.idsdudent);
                return true;
            }
            return false;
        });
        return filtered;
    }

    const distictdata = removeDuplicates(data);

    const listItemsMatiersPercent = distictdata.map(mat => {
        return (
            <tr key={mat.id}>
                <td>{mat.name}</td>
                <td>{matiereSelected}</td>
                <td> {countOccurrences(mat.idsdudent, matiereSelected)} </td>
                <td style={{ backgroundColor: (countOccurrences(mat.idsdudent, matiereSelected) / mat.nbh) * 100 > 10 ? 'red' : 'lightblue' }}> {((countOccurrences(mat.idsdudent, matiereSelected) / mat.nbh) * 100).toFixed(2)}% </td>
            </tr>);
    }
    );

    const listItems = () => {
        return data
            .filter(person => person.name && person.name.toLowerCase().includes(name.toLowerCase()))
            .filter(person => person.matiere && person.matiere.toLowerCase().includes(matiereSelected.toLowerCase()))

            .map(person => (
                <tr key={person.id}> {/* Ensure each child in a list has a unique "key" prop. */}
                    <td>{person.name}</td>
                    <td>{person.date ? person.date.substring(0, 10) : " "}</td>
                    <td>{person.matiere}</td>
                    <td>
                        {person.description ?
                            <CheckCircleRoundedIcon style={{ color: 'green', height: '40px', width: '40px' }} /> : < CancelRoundedIcon style={{ color: 'red', height: '40px', width: '40px' }} />
                        }
                    </td>
                </tr>
            ));
    };


    const listItemsStudents = () => {
        return students
            .map(person => (
                <tr key={person.id}> {/* Ensure each child in a list has a unique "key" prop. */}
                    <td>{person.Name}</td>
                    <td>{person.Email}</td>
                    <td>{person.tel}</td>
                </tr>
            ));
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-2">
                    <SidebarProf />
                </div>
                <div className="col-9" style={{ marginTop: "100px" }}>
                    <div className="row">

                        <div className="col-2 mb-2">
                            <label
                                htmlFor="student"
                                className="form-label"
                            >
                                Student
                            </label>
                            <input
                                required
                                type="text"
                                className="form-control"
                                id="student"
                                name="student"
                                placeholder="nom"
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>

                        <div className="col-2 mb-2">
                            <label
                                htmlFor="aaa"
                                className="form-label"
                            >
                                Matiere
                            </label>
                            <select
                                className="form-select"
                                id="aaa"
                                name="aaa"
                                value={matiereSelected}
                                onChange={(e) => setMatiere(e.target.value)}
                            >
                                <option value="" >All</option>
                                {listMatiere}
                            </select>
                        </div>

                    </div>
                    <br />
                    <div className="row">
                        <div className="col-12">
                            <div class="recent-orders">
                                <h2>Student</h2>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Date</th>
                                            <th>Matiere</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listItems()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="recent-orders">
                            <h2>Absence</h2>
                            {matiereSelected !== "" &&
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Student</th>
                                            <th>Matiere</th>
                                            <th>Absence</th>
                                            <th>Pourcentage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listItemsMatiersPercent}
                                    </tbody>
                                </table>
                            }
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />

                        </div>
                    </div>
                    <div className="row">
                        <div className="recent-orders">
                            <h2>Students</h2>

                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>N° telephone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listItemsStudents()}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />


        </div>
    );

}


export default ClassesProf;