import SidebarAdmin from "../Admin/SidebarAdmin";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuth } from "../../Redux/userReducer";
import axios from "axios";
import PopupAffectStudent from "../Widget/PopupAffectStudent";
import PopUpProfDetails from "../Widget/PopUpProfDetails";

import InfoIcon from '@mui/icons-material/Info';




function UserAdmin() {
    const pathServer = process.env.REACT_APP_API_URL    //const pathLocal="http://localhost:4000"; 


    const [userToaffect, setUserToaffect] = useState({});
    const auth = useSelector((state) => state.auth);
    const [selection, setSelection] = useState("");
    const [data, setData] = useState([{}]);
    const history = useNavigate();
    const dispatch = useDispatch();


    const [isOpen, setIsOpen] = useState(false);


    const togglePopup = () => {
        setIsOpen(!isOpen);
    }


    const [isOpenPopUpProf, setIsOpenPopUpProf] = useState(false);

    const togglePopupProf = (e) => {
        setIsOpenPopUpProf(!isOpenPopUpProf);
    }


    // verfy that is loged in if not it should Sign in 
    useEffect(() => {
        dispatch(getAuth());
        if (auth.isAuth) {
            if (auth.user.Role === "Student") {
                history("/Absenceuser");
            }

            if (auth.user.Role === "Admin") {
                history("/Useradmin");
            }
        }
        else {
            history("/");
        }
    }, [auth.isAuth, auth.user.Role, dispatch, history]);

    useEffect(() => {
        const getUsers = (e) => {
            if (selection === "Student") {
                // path = pathStudents;
            }
            else {
                // path = pathAll;
            }
            axios.get(pathServer + "/getallusers")
                .then(async (res) => {
                    setData(res.data);
                })
                .catch((e) => {
                }
                );
        }
        // Call the fetchData function when the component mounts
        getUsers();

        // Cleanup function (optional): This will be called when the component is unmounted
        return () => {
            // You can perform cleanup here if needed
        };
    }, [pathServer, selection]);

    const getTD = (v) => {
        if (v.Role === "Student") {
            if (v.class) {
                return <td> Affected </td>
            }
            else {
                return <td> <button onClick={() => { togglePopup(); setUserToaffect(v); }} type="button" className="btn btn-info">No Affected</button>
                </td>
            }
        }
        else if (v.Role === "Prof") {
            return <td> <button onClick={() => { togglePopup(); setUserToaffect(v); }} type="button" className="btn btn-info"> Affect</button>
            </td>
        }
        else {
            return <td>  </td>
        }
    }

    const listItems = data
        .filter(person => person.Role && person.Role.toLowerCase().includes(selection.toLowerCase()))
        .map((person, index) => (
            <tr key={person.Email || index}>
                <td>{person.Name}</td>
                <td>{person.Email}</td>
                <td>{person.Role}</td>
                {getTD(person)}
                <td>{person.class ? person.class : person.Role === "Prof" ? <InfoIcon onClick={(e) => { togglePopupProf(e); setUserToaffect(person); }} style={{ color: '#DAF7A6', height: '30px', width: '30px' }} /> : ""}</td>
            </tr>
        ));





    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-2">
                    <SidebarAdmin />
                </div>
                <div className="col-10" style={{ marginTop: "100px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {isOpen && <PopupAffectStudent
                                    content={<>
                                        <b>Affecter  {userToaffect.Name}  à la classe</b>
                                        <br />
                                        <br />
                                        <p>{userToaffect.Email}  </p>
                                    </>}
                                    user={userToaffect}
                                    handleClose={togglePopup}
                                />}

                                {isOpenPopUpProf && <PopUpProfDetails
                                    content={<>
                                        <b>Detail apropos proffeseur</b>
                                        <br />
                                        <br />
                                        <p>{userToaffect.Email}  </p>
                                    </>}
                                    user={userToaffect}
                                    handleClose={togglePopupProf}
                                />}
                            </div>
                            <div className="col-3 mb-3">
                                <label
                                    htmlFor="role"
                                    className="form-label"
                                >
                                    Role
                                </label>
                                <select
                                    className="form-select"
                                    id="role"
                                    name="role"
                                    value={selection}
                                    onChange={(e) => setSelection(e.target.value)}
                                >
                                    <option value="">All</option>
                                    <option value="Student">Student</option>
                                    <option value="Prof">Prof</option>
                                    <option value="Admin">Admin</option>
                                </select>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-12">
                                <div className="recent-orders">
                                    <h2>Utilisateurs</h2>
                                    <br/>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Role</th>
                                                <th>Status</th>
                                                <th>Class</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listItems}
                                        </tbody>
                                    </table>
                                    <a href="/">...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserAdmin;