import '../../css/sidebar.css';
import SidebarStudent from "../../components/Student/SidebarStudent";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getAuth } from "../../Redux/userReducer";



function NoteStudent() {
    const pathServer = process.env.REACT_APP_API_URL    //const pathLocal="http://localhost:4000"; 


    const [matiereSelected, setMatiere] = useState("All");
    const [semesterSelected, setSemester] = useState("1");
    const [matieres, setMatieres] = useState([{}]);
    const [note, setNotes] = useState([]);

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    


    const listMatiere = matieres.map(mat =>
        <option value={mat.Name} > {mat.Name} </option>
    );


    useEffect(() => {
        if (!auth.isAuth) {
            dispatch(getAuth());
            //setId(auth.user.id);
        }
        else {
            axios.get(`${pathServer}/getMatierebyclassinsemester/` + auth.user.class + "/" + semesterSelected)
                .then(async (res) => {
                    setMatieres(res.data);
                })
                .catch((e) => {
                }
                );
        }

    }, [auth.isAuth, auth.user.class, dispatch, pathServer, semesterSelected]);


    useEffect(() => {
        if (!auth.isAuth) {
            dispatch(getAuth());
            //setId(auth.user.id);
        }
        else {
            axios.get( `${pathServer}/getnotebystudent/` + auth.user.id)
                .then(async (res) => {
                    setNotes(res.data);
                })
                .catch((e) => {
                }
                );
        }
    }, [auth.isAuth, auth.user.id, dispatch, pathServer]);

    const colorNoteMartiere = (note) => {
        if(note < 10)
        return "#F35D3D"; 

        if(note >= 10)
        return "#A6D493";
    }

    const filteredData = (n) => {
        return note.filter(notes => notes.Matiere === n);
    };

    const listItemsMatiersTab = matieres.map(mat => {
        const d = filteredData(mat.id);

        if (d.length === 0) {
            return null;
        }
        else {

            const itemTableOfMatiere = d.map(m =>
                <tr>
                    <td>{m.Type}</td>
                    <td style={{ backgroundColor: colorNoteMartiere(m.note) }}>{m.note}</td>
                </tr>
            );
            return (
                <div className='col-3'>
                    <div className="recent-orders">
                        <table>
                            <thead>
                                <tr>
                                    <th>{mat.Name}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {itemTableOfMatiere}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }

    }
    );

    return (
        <>
            <SidebarStudent />
            <br /><br /><br />
            <div className="container">
                <br />
                <div className="row">
                    <div className='col-1'></div>
                    <div className="col-2 mb-2">
                        <label
                            for="role"
                            className="form-label"
                        >
                            Semester
                        </label>
                        <select
                            className="form-select"
                            id="semester"
                            name="semester"
                            value={semesterSelected}
                            onChange={(e) => setSemester(e.target.value)}
                        >
                            <option selected value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                    </div>

                    <div className="col-2 mb-2">
                        <label
                            for="role"
                            className="form-label"
                        >
                            Matiere
                        </label>
                        <select
                            className="form-select"
                            id="role"
                            name="role"
                            value={matiereSelected}
                            onChange={(e) => setMatiere(e.target.value)}
                        >
                            <option value="All" >All</option>
                            {listMatiere}
                        </select>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-11">
                        <div className='row'>
                        {listItemsMatiersTab}
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
}

export default NoteStudent;